import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { ToastComponent } from './shared/ui/toast/toast.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { tokenInterceptor } from './shared/utils/interceptors/token.interceptor';
import { provideToastr } from 'ngx-toastr';
import { errorsInterceptor } from './shared/utils/service/errors/errors.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPolishPaginatorIntl } from './shared/utils/polish-pagination.intl';
import { provideQuillConfig } from 'ngx-quill';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import {
  CUSTOM_DATE_FORMATS,
  CustomNativeDateAdapter,
} from './shared/utils/mat-custom-date-format';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr({
      toastComponent: ToastComponent,
    }),
    provideAngularSvgIcon(),
    provideHttpClient(withInterceptors([tokenInterceptor, errorsInterceptor])),
    { provide: MatPaginatorIntl, useValue: getPolishPaginatorIntl() },
    provideQuillConfig({}),
    provideNativeDateAdapter(),
    {
      provide: DateAdapter,
      useClass: CustomNativeDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
  ],
};
