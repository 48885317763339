import { Injectable, Signal, effect, signal } from '@angular/core';
import { fromEvent, map, startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private mobileThreshold = 768;

  private isMobile$ = fromEvent(window, 'resize').pipe(
    startWith(this.checkIfMobile()),
    map(() => this.checkIfMobile()),
  );

  isMobile: Signal<boolean> = toSignal(this.isMobile$, {
    initialValue: this.checkIfMobile(),
  });

  private checkIfMobile(): boolean {
    return window.matchMedia(`(max-width: ${this.mobileThreshold}px)`).matches;
  }
}
