import { Component } from '@angular/core';
import {SvgIconComponent} from 'angular-svg-icon';

@Component({
  selector: 'pi-mobile-view-blocker',
  imports: [
    SvgIconComponent
  ],
  templateUrl: './mobile-view-blocker.component.html',
  styleUrl: './mobile-view-blocker.component.scss'
})
export class MobileViewBlockerComponent {

}
