<div class="pi-mobile-blocker">
  <div class="pi-wallet-boi-filler__icon">
    <svg-icon
      src="/icons/warning-triangle.svg"
      [svgStyle]="{
        'width.px': 250,
        'height.px': 250,
        fill: 'var(--table-border-color)'
      }"
    ></svg-icon>
  </div>
  <div class="pi-mobile-blocker__title">
    Strona niedostępna na urządzeniach mobilnych
  </div>
  <div class="pi-mobile-blocker__subtitle">
    Aby zapewnić najlepsze doświadczenie użytkownika, ta strona nie jest
    dostępna na urządzeniach mobilnych. Spróbuj otworzyć ją na komputerze lub
    tablecie z większym ekranem.
  </div>
</div>
