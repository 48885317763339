import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Theme, ThemeService } from './shared/utils/service/theme.service';
import { FontSizeService } from './shared/utils/service/font-size.service';
import { fadeInOut } from '../theme/animations';
import { DeviceService } from './shared/utils/service/device.service';
import {MobileViewBlockerComponent} from './shared/ui/components/mobile-view-blocker/mobile-view-blocker.component';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, MobileViewBlockerComponent],
  templateUrl: './app.component.html',
  standalone: true,
  animations: [fadeInOut(0.1)],
  styleUrl: './app.component.scss',
})
export class AppComponent {
  themeService = inject(ThemeService);
  fontSizeService = inject(FontSizeService);
  blueLightFilter = this.themeService.blueLightFilter;

  isMobile = inject(DeviceService).isMobile;

  title = 'panel-inwestora';
  theme: string = 'theme-light';
  fontSize: number = 10;

  changeTheme(theme: Theme) {
    this.themeService.changeTheme(theme);
  }

  setFontSize(fontSize: number) {
    this.fontSizeService.changeFontSize(fontSize);
  }
}
